import { Box, Button, Stack, Typography } from '@mui/material';
import JobHistoryCard from './JobHistoryCard';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const kyrosExperience = [
  'Built a React Native mobile app from scratch',
  'Implemented front-end solutions using modern frameworks such as React enhancing user engagement andinterface responsiveness',
  'Worked in our Node.js API to architect scalable and efficient server-side solutions, ensuring high performance and reliability',
  'Collaborated closely with cross-functional teams including product management and design to translate business requirements into delivery of key features and functionalities.',
];

const freeLanceExperience = [
  'Built Sprouts, an iOS app using SwiftUI, that helps track your grocery expiration dates and suggests recipes based on your saved groceries.',
  'Developed an NBA score tracker iOS app using SwiftUI and a SwiftSoup script for scraping data.',
];

const genesisExperience = [
  'I wrote efficient search queries and then constructed pages for users to view up the minute fuel flow data',
  'I created frontend pages to display realtime data of our current inventory and pipeline data',
  'I worked on bug fixing and troubleshooting in the Oracle Business Intelligence framework and delivered specific pipeline data to users who needed it accurate and up to date',
  'I developed labels for use in warehouses and inventory sorting systems',
];

const fueltrustExperience = [
  'I assisted in designing and building beautiful and performant user interfaces to maximize user experience',
  'I worked on a team that went from 0 lines of code to a robust web portal with a mature code base of reusable and powerful components',
  'I wrote unit tests in Jest to test our front end',
  'I worked with the backend team to design our frontend to best connect our respective pieces',
  'I met with users and developed user stories that were then itemized and developed to make the best product for our customers',
];

const vallyExperience = [
  'Enhanced and maintained front-end and back-end components of a Next.js monorepo, utilizing TypeScript for robust, scalable development',
  'Led key initiatives, including a homepage overhaul and development of complex payment and scheduling features, driving improved functionality and user experience',
  'Designed and implemented RESTful APIs and MongoDB schemas to streamline data processing and enhance platform integration',
  'Coordinated across front-end and back-end tasks, ensuring seamless integration and adherence to modern web development best practices',
];

export default function JobHistorySection() {
  const navigate = useNavigate();
  const goHome = () => navigate('/');

  const isScreenSmall = useMediaQuery('(max-width: 900px)');
  const isScreenVerySmall = useMediaQuery('(max-width: 600px)');
  return (
    <Box margin={isScreenSmall ? 6 : 12}>
      <Stack direction={isScreenVerySmall ? 'column' : 'row'} style={{ paddingBottom: 8, justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography fontWeight={800} fontSize={isScreenSmall ? 28 : 70}>
          Job History
        </Typography>
        <Button style={{ maxHeight: isScreenSmall ? 40 : 80 }} onClick={goHome}>
          go home
        </Button>
      </Stack>

      <Stack spacing={2}>
        <JobHistoryCard
          jobTitle="Contract Fullstack Web Engineer"
          companyTitle="Vally: 8/2024 - Present"
          experience={vallyExperience}
        />
        <JobHistoryCard
          jobTitle="Fullstack Web Engineer"
          companyTitle="Kyros: 07/2023 - Current"
          experience={kyrosExperience}
        />
        <JobHistoryCard
          jobTitle="Freelance Mobile App Engineer"
          companyTitle="2019 - Current"
          experience={freeLanceExperience}
        />

        <JobHistoryCard
          jobTitle="Front End Web Engineer"
          companyTitle="FuelTrust: 11/2020 - 11/2022"
          experience={fueltrustExperience}
        />
        <JobHistoryCard
          jobTitle="Software Engineer"
          companyTitle="Genesis Energy: 08/2020 - 05/2023"
          experience={genesisExperience}
        />
      </Stack>
    </Box>
  );
}
