import { createTheme } from '@mui/material';

export const palette = {
  ui_navy_2: '#FAFBFB',
  ui_navy_4: '#F6F6F7',
  ui_navy_8: '#EDEDEF',
  ui_navy_12: '#E3E5E8',
  ui_navy_16: '#DADCE0',
  ui_navy_24: '#C8CAD0',
  ui_navy_32: '#B5B9C1',
  ui_navy_40: '#A3A7B1',
  ui_navy_48: '#9195A2',
  ui_navy_56: '#7E8492',
  ui_navy_64: '#6C7283',
  ui_navy_72: '#596173',
  ui_navy_76: '#50586C',
  ui_navy_80: '#474F64',
  ui_navy_84: '#3E465C',
  ui_navy_88: '#353D54',
  ui_navy_92: '#2B354D',
  ui_navy_96: '#222C45',
  ui_navy_98: '#1E2741',
  ui_navy_100: '#19233D',

  ui_teal_2: '#FAFDFD',
  ui_teal_4: '#F5FAFA',
  ui_teal_8: '#EBF5F5',
  ui_teal_12: '#E1F0F1',
  ui_teal_16: '#D8EBEC',
  ui_teal_24: '#C4E1E2',
  ui_teal_32: '#B0D7D9',
  ui_teal_40: '#9DCDCF',
  ui_teal_48: '#89C2C6',
  ui_teal_56: '#75B8BC',
  ui_teal_64: '#61AEB3',
  ui_teal_72: '#4EA4A9',
  ui_teal_76: '#449FA5',
  ui_teal_80: '#3A9AA0',
  ui_teal_84: '#30959B',
  ui_teal_88: '#279096',
  ui_teal_92: '#1D8B92',
  ui_teal_96: '#13868D',
  ui_teal_98: '#0E838A',
  ui_teal_100: '#098188',

  status_blue_1: '#004FC4',
  status_blue_2: '#0063F7',
  status_blue_3: '#5B8DEF',
  status_blue_4: '#9DBFF9',
  status_blue_5: '#E5F0FF',

  status_green_1: '#05A660',
  status_green_2: '#06C270',
  status_green_3: '#39D98A',
  status_green_4: '#57EBA1',
  status_green_5: '#E3FFF1',

  status_yellow_1: '#E5B800',
  status_yellow_2: '#FFCC00',
  status_yellow_3: '#FDDD48',
  status_yellow_4: '#FDED72',
  status_yellow_5: '#FFFEE5',

  status_orange_1: '#E57A00',
  status_orange_2: '#FF8800',
  status_orange_3: '#FDAC42',
  status_orange_4: '#FCCC75',
  status_orange_5: '#FFF8E5',

  status_red_1: '#E53535',
  status_red_2: '#FF3B3B',
  status_red_3: '#FF5C5C',
  status_red_4: '#FF8080',
  status_red_5: '#FFE5E5',

  white: '#FFFFFF',
};

export const myTheme = createTheme({
  typography: {
    allVariants: {
      color: palette.ui_navy_24,
    },
    fontFamily: 'Poppins',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontWeight: 300,
      fontSize: '2.375rem',
      lineHeight: '3.5rem',
    },
    h2: {
      fontWeight: 300,
      fontSize: '2rem',
      lineHeight: '2.75rem',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.675rem',
      lineHeight: '2.5rem',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.375rem',
      lineHeight: '2.25rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '.875rem',
    },
    caption: {
      fontWeight: 400,
      fontSize: '.75rem',
    },
  },
  palette: {
    background: {
      default: '#000000',
    },
    primary: {
      main: palette.ui_teal_100,
    },
    secondary: {
      main: palette.ui_teal_16,
      dark: palette.ui_teal_32,
    },
    tertiary: {
      main: palette.ui_navy_100,
    },
    error: {
      light: palette.status_red_4,
      main: palette.status_red_1,
    },
    warning: {
      light: palette.status_orange_4,
      main: palette.status_orange_1,
    },
    info: {
      light: palette.status_blue_4,
      main: palette.status_blue_2,
    },
    success: {
      light: palette.status_green_4,
      main: palette.status_green_1,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          color: palette.status_blue_5,
          fontSize: 30,
          backgroundColor: palette.ui_navy_100,
          '&:hover': {
            backgroundColor: palette.ui_navy_32,
            color: palette.white,
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 2,
          }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: 'currentcolor',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: palette.white,
          }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: 1.5,
          color: palette.ui_navy_80,
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h4',
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: palette.ui_teal_12,
            color: palette.ui_navy_88,
            marginBottom: theme.spacing(2),
          }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: palette.ui_teal_2,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            paddingX: 3,
          }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          fontWeight: 'bold',
        },
        separator: {
          color: palette.ui_navy_32,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          display: 'block !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.ui_navy_48,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: palette.ui_navy_48,
        },
      },
    },
  },
});
