import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { palette } from '../myTheme';
import { useNavigate } from 'react-router-dom';

export default function ProjectCard(props) {
  const navigate = useNavigate();
  const navigateToTC = () => navigate(props.pageLink);
  return (
    <Card onClick={navigateToTC} style={{ backgroundColor: palette.ui_navy_100 }}>
      <CardContent>
        <Stack style={{ paddingTop: 2 }}>
          <Stack gap={2} style={{ alignItems: 'center', justifyContent: 'center' }} direction="row">
            <Box component="img" sx={{ height: 90, width: 90, borderRadius: 4 }} alt="app icon" src={props.img} />
            <Stack style={{ paddingLeft: 20, alignItems: 'start' }}>
              <Typography fontWeight={800} variant="h2">
                {props.title}
              </Typography>

              {props.experience.map((line) => (
                <Grid container sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                  <Grid item>
                    <Typography variant="h5">{line}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
