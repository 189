import { Box, Button, Stack, Typography } from '@mui/material';
import GTicon from '../components/images/GTAppIcon.jpg';
import TCicon from '../components/images/TCAppIcon.jpg';
import KYROSicon from '../components/images/kyrosAppIcon.jpg';

import { useNavigate } from 'react-router-dom';
import ProjectCard from './ProjectCard';

import useMediaQuery from '@mui/material/useMediaQuery';
import ProjectCardMobile from './ProjectsCardMobile';

const sproutsDescription = [
  'Sprouts is a simple but effective way to make sure you can see which groceries are going bad and which need to be cooked soon. log your groceries and their expiration dates when you buy them and the app will let you know when specific ingredients are nearing their expiration date.',
];

const tipCalcDescription = [
  'This is a lightweight and fully featured tip calculator and bill splitter. You can evenly split a bill and tip for up to 20 people. This app also features a round up feature to round your bill up to the nearest dollar if you so desire. Built with SwiftUI it is incredibly lightweight and performant.',
];

const kyrosDescription = [
  'Kyros offers a transformative digital platform designed to guide individuals on their recovery wellness journey. Seamlessly connecting users with professional support and essential resources, Kyros revolutionizes the landscape of holistic recovery. Our human-centric approach ensures personalized care, fostering positive outcomes for millions impacted by substance use challenges. Experience the power of the Kyros platform, empowering you to navigate your path to recovery.']

export default function ProjectsSection() {
  const navigate = useNavigate();
  const goHome = () => navigate('/');

  const isScreenSmall = useMediaQuery('(max-width: 900px)');
  const isScreenVerySmall = useMediaQuery('(max-width: 600px)');
  return (
    <Box margin={isScreenSmall ? 6 : 12}>
      <Stack spacing={2}>
        <Stack direction={isScreenVerySmall ? 'column' : 'row'} style={{ paddingBottom: 8, justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontWeight={800} fontSize={isScreenSmall ? 28 : 70}>
            Projects
          </Typography>
          <Button style={{ maxHeight: isScreenSmall ? 40 : 80 }} onClick={goHome}>
            go home
          </Button>
        </Stack>
        {isScreenSmall ? (
          <Stack spacing={4}>
            <ProjectCardMobile
              pageLink="/projects/kyrosApp"
              img={KYROSicon}
              title="Kyros - Recovery Support"
            />
            <ProjectCardMobile
              pageLink="/projects/groceryTracker"
              img={GTicon}
              title="Sprouts: Grocery Tracker"
            />
            <ProjectCardMobile
              pageLink="/projects/tipCalculator"
              img={TCicon}
              title="Bill Splitter and Tip Calculator"
            />

          </Stack>
        ) : (
          <Stack spacing={2}>
            <ProjectCard
              pageLink="/projects/kyrosApp"
              img={KYROSicon}
              title="Kyros - Recovery Support"
              experience={kyrosDescription}
            />
            <ProjectCard
              pageLink="/projects/groceryTracker"
              img={GTicon}
              title="Sprouts: Grocery Tracker"
              experience={sproutsDescription}
            />
            <ProjectCard
              pageLink="/projects/tipCalculator"
              img={TCicon}
              title="Bill Splitter and Tip Calculator"
              experience={tipCalcDescription}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
