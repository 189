import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { palette } from '../myTheme';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function JobHistoryCard(props) {
  const isScreenSmall = useMediaQuery('(max-width: 900px)');
  return (
    <Card style={{ backgroundColor: palette.ui_navy_100 }}>
      <CardContent>
        <Stack style={{ paddingTop: 2 }}>
          <Typography fontWeight={800} variant={isScreenSmall ? 'h4' : 'h2'}>
            {props.jobTitle}
          </Typography>
          <Typography style={{ paddingTop: 5 }} variant="h4">
            {props.companyTitle}
          </Typography>
          <Stack style={{ paddingLeft: 8, alignItems: 'start' }}>
            {props.experience.map((line) => (
              <Grid container sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                <Grid item>
                  <Typography sx={{ display: 'list-item' }} variant={isScreenSmall ? 'body2' : 'h5'}>
                    {line}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
