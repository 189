import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { palette } from '../myTheme'
import { useNavigate } from 'react-router-dom'

export default function ProjectCardMobile(props) {
  const navigate = useNavigate()
  const navigateToTC = () => navigate(props.pageLink)
  return (
    <Card
      onClick={navigateToTC}
      style={{ backgroundColor: palette.ui_navy_100 }}
    >
      <CardContent>
        <Stack style={{ paddingTop: 2 }}>
          <Stack
            gap={2}
            style={{ alignItems: 'start', justifyContent: 'center' }}
            direction='column'
          >
            <Box
              component='img'
              sx={{ height: 60, width: 60, borderRadius: 3 }}
              alt='app icon'
              src={props.img}
            />
            <Typography fontWeight={800} variant='h2'>
              {props.title}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
