import { Button, Card, Link, Stack, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router';
import { palette } from '../myTheme';


export default function ContactPage() {

  const isScreenSmall = useMediaQuery('(max-width: 900px)');
  const isScreenVerySmall = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();

  const goHome = () => navigate('/');

  return (
    <Stack gap={2} padding={isScreenSmall ? 4 : 12}>
      <Stack direction={isScreenVerySmall ? 'column' : 'row'} style={{ paddingBottom: 8, justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography fontWeight={800} fontSize={isScreenSmall ? 28 : 70}>
          Contact Me
        </Typography>
        <Button style={{ maxHeight: isScreenSmall ? 40 : 80 }} onClick={goHome}>
          go home
        </Button>
      </Stack>
      <Card variant="outlined" style={{ maxWidth: isScreenSmall ? '125%' : 1800, backgroundColor: palette.ui_navy_80, padding: 40 }}>

        <Typography fontWeight={800} style={{ textAlign: isScreenSmall ? 'center' : 'start' }} fontSize={isScreenSmall ? 26 : 40}>
          I'm currently looking for new opportunities. Feel free to reach out!
        </Typography>
        <Typography fontWeight={800} fontSize={isScreenSmall ? 20 : 40}>
          <p>Email:</p> lincolnanderson@me.com
        </Typography>
        <Typography fontWeight={800} fontSize={isScreenSmall ? 20 : 40}>
          <p>Github:</p> <Link color={palette.white} href="https://github.com/lincoln-anderson">lincoln-anderson</Link>
        </Typography>
        <Typography fontWeight={800} fontSize={isScreenSmall ? 20 : 40}>
          <p>LinkedIn:</p> <Link color={palette.white} href="https://www.linkedin.com/in/lincoln-anderson-79146011a/">Lincoln Anderson</Link>
        </Typography>
      </Card>
    </Stack>

  );
}
