import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import { AppRoutes } from './routes/AppRoutes';
import { myTheme } from './myTheme';
import { CssBaseline } from '@mui/material';

<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>;

const App = () => {
  return (
    <ThemeProvider theme={myTheme}>
      <CssBaseline>
        <AppRoutes />
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
